#overview_view_gallery
  display: flex
  flex-direction: column
  gap: 8px
  list-style: none
  margin: 0
  padding: 0

  & > .item
    & > .image
      display: block
      width: 100%
