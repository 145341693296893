@import 'src/styles/shared'

.biterior_view_right_wrapper
  display: flex
  flex: 0 0 440px
  flex-direction: column
  height: 100%
  overflow-y: hidden
  padding-top: 20px

  @media (min-width: 1600px)
    padding-top: 40px

  @media (min-width: 1800px)
    flex: 0 0 550px

  & > .tab_menu-wrapper
    margin-bottom: 24px

  & > .tab_content-wrapper
    @extend %scrollbar
    height: 100%
    overflow-y: scroll

    & > .tab_content
      padding-bottom: 56px

      & > .content-wrapper
        margin-bottom: 60px

      & > .button-wrapper
        display: flex
        justify-content: flex-end
