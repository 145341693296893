@import 'src/styles/shared'

.default_button
  align-items: center
  border:
    radius: 36px
    style: solid
    width: 1px
  cursor: pointer
  display: flex
  flex-direction: row
  gap: 10px
  padding: 12px 24px

  &.-primary
    background-color: $color-green-dark
    border-color: $color-green-dark
    color: $color-black

  &.-secondary
    background-color: $color-white
    border-color: $color-blue-dark
    color: $color-blue-dark

    & > .icon
      & > path
        fill: $color-blue-dark

  &.-primary,
  &.-secondary
    &.-active,
    &:hover,
    &:active
      &:not(:disabled)
        background-color: $color-blue-dark
        border-color: $color-blue-dark
        color: $color-white

        & > .icon
          & > path
            fill: $color-white

  &:disabled
    background-color: $color-grey-light
    border-color: $color-grey-light
    color: $color-grey-dark

  & > .label
    display: block

  & > .icon
    display: block
    height: 16px
    width: 16px
