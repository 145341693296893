@import 'src/styles/shared'

.overview_view_right_wrapper
  display: flex
  flex: 1 1 auto
  flex-direction: column
  height: 100%
  overflow: hidden

  & > .tab_menu-wrapper
    margin-bottom: 24px

  & > .tab_content-wrapper
    @extend %scrollbar
    height: 100%
    overflow-y: scroll

    & > .tab_content
      padding-bottom: 75px

      & > #summary
        background-color: $color-grey-light
        padding: 54px
