@font-face
  font-family: "ShapeSans Bold"
  font-weight: bold
  src: url("../assets/fonts/ShapeSans/ShapeSans-Bold.woff") format("woff"), url("../assets/fonts/ShapeSans/ShapeSans-Bold.woff") format("woff")

@font-face
  font-family: "ShapeSans Medium"
  font-weight: bold
  src: url("../assets/fonts/ShapeSans/ShapeSans-Medium.woff") format("woff"), url("../assets/fonts/ShapeSans/ShapeSans-Medium.woff") format("woff")

@font-face
  font-family: "ShapeSans Regular"
  src: url("../assets/fonts/ShapeSans/ShapeSans-Regular.woff") format("woff"), url("../assets/fonts/ShapeSans/ShapeSans-Regular.woff2") format("woff2")
