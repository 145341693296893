@import 'src/styles/shared'

#concept_view
  align-items: center
  display: flex
  height: 100%

  .concept_view_container
    width: 100%

    @media (min-width: 768px)
      width: 720px

    @media (min-width: 992px)
      width: 960px

    @media (min-width: 1200px)
      width: 1140px

    @media (min-width: 1400px)
      width: 1320px

    @media (min-width: 1600px)
      width: 1520px

    @media (min-width: 1800px)
      width: 1720px

    @media (min-width: 2000px)
      width: 1920px

  .concept
    display: flex
    flex-direction: column

    @media (min-width: 1200px)
      //gap: 62px

    & > .content-wrapper
      display: flex
      flex-direction: row

      & > .content
        display: flex
        flex-direction: row
        gap: 40px

        @media (min-width: 1400px)
          gap: 80px

        & > .separator
          background-color: $color-black
          border: none
          display: block
          flex: 0 0 1px
          height: 100%
          margin: 0

        & > .instruction-wrapper
          flex: 1 1 40%

          & > .instruction
            padding: 32px 0 0

            @media (min-width: 1200px)
              padding: 32px 0

            & > .title-wrapper
              margin-bottom: 32px

              & > .title
                color: $color-blue-dark
                font-family: 'ShapeSans Medium', sans-serif
                font-size: 24px
                line-height: 30px
                margin-bottom: 0

                @media (min-width: 1200px)
                  font-size: 34px
                  line-height: 40px

            & > .text-wrapper
              font-size: 18px
              line-height: 22px

        & > .child_group_list-wrapper
          flex: 1 0 auto

          @media (min-width: 2000px)
            flex: 0 1 auto

        & > .floor_list-wrapper
          flex: 0 1 auto


    & > .bottom-wrapper
      & > .bottom
        align-items: center
        display: flex
        flex-direction: row
        justify-content: space-between

        & > .buddy-wrapper
          & > .buddy
            display: block
            height: auto
            width: 180px
