@import 'src/styles/shared'

#biterior_view
  height: 100%

  .biterior
    display: flex
    flex-direction: row
    gap: 20px
    height: 100%
    justify-content: space-between

    @media (min-width: 1600px)
      gap: 40px
      padding: 0 80px

    @media (min-width: 2000px)
      padding: 0
