@import 'src/styles/shared'

#summary
  & > .title-wrapper
    margin-bottom: 16px

    & > .title
      color: $color-blue-dark
      font:
        size: 24px
        weight: normal
      line-height: 28px
      margin-bottom: 0
