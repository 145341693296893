// Colors
$color-black: #000000
$color-white: #FFFFFF

$color-grey-dark: #737373
$color-grey-medium: #D6D6D6
$color-grey-light: #F5F5F5

$color-blue-dark: #143275
$color-blue-light: #3C589E

$color-green-dark: #3ECE7B
$color-green-light: #CAE7D3
