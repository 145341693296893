@import 'src/styles/shared'

#header
  align-items: center
  background-color: $color-white
  display: flex
  flex: 0 0 120px
  justify-content: space-between
  padding: 0 54px
  width: 100%

  & > .logo-wrapper
    justify-self: flex-start
    width: 190px

    & > .logo
      display: block
      height: auto
      max-width: 100%

  & > .configurator_menu-wrapper
    justify-self: center

  & > .header_menu-wrapper
    justify-self: flex-end
