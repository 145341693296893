@import 'src/styles/shared'

.anchor
  align-items: center
  background: none
  border: none
  cursor: pointer
  display: flex
  gap: 14px
  padding: 0 6px

  & > .label
    color: $color-blue-dark
    font-size: 18px
    line-height: 26px
