@import 'src/styles/shared'

#configuration
  & > .title-wrapper
    margin-bottom: 8px

    & > .title
      font:
        size: 24px
        weight: normal
      line-height: 28px
      margin-bottom: 0

  & > .content-wrapper
    margin-bottom: 40px

    & > .content
      & > .text-wrapper
        margin-bottom: 24px

        & > .paragraph
          margin-bottom: 0

      & > .note-wrapper
        @extend %note-wrapper

  & > .dropdown_list-wrapper
    & > .dropdown_list
      display: flex
      flex-direction: column
