#overview_view
  align-items: center
  height: 100%
  padding-top: 40px

  @media (min-width: 1200px)
    padding-top: 80px

  .overview
    display: flex
    flex-direction: row
    gap: 20px
    height: 100%
    justify-content: space-between
    padding: 0 40px

    @media (min-width: 1600px)
      gap: 40px
      padding: 0 80px

    @media (min-width: 2000px)
      padding: 0
