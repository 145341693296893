.overview_view_left_wrapper
  display: flex
  flex: 0 0 440px
  flex-direction: column
  gap: 40px
  padding:
    bottom: 40px
    right: 24px !important

  @media (min-width: 1800px)
    flex: 0 0 550px
