#alert
  &::before
    background-color: rgba(0, 0, 0, 0.2)
    content: ''
    display: block
    height: 100vh
    left: 0
    position: fixed
    top: 0
    width: 100vw
    z-index: 20

  & > .box
    background-color: white
    border-radius: 4px
    display: flex
    flex-direction: column
    left: 50%
    min-width: 460px
    padding: 80px
    position: fixed
    top: 50%
    transform: translateX(-50%) translateY(-50%)
    z-index: 21

    & > .icon-wrapper
      margin-bottom: 26px

      & > .icon
        display: block
        height: 50px
        margin: 0 auto
        transform: rotate(180deg)
        width: 50px

    & > .message-wrapper
      margin-bottom: 32px
      text-align: center

      & > .message
        font-size: 18px
        line-height: 26px
        margin: 0

    & > .button-wrapper
      & > .button
        margin: 0 auto
