@import 'src/styles/shared'

#header_menu
  & > .menu
    display: flex
    gap: 16px
    list-style: none
    margin: 0
    padding: 0

    & > .item
      & > .button
        flex-direction: row-reverse
        padding: 4px 16px

