@import 'src/styles/shared'

#configurator_menu
  & > .menu
    display: flex
    gap: 18px
    justify-content: space-around
    list-style: none
    margin: 0
    padding: 0

    & > .item
      align-items: center
      display: flex
      flex-direction: row
      gap: 18px

      &.-active
        & > .step_button
          & > .icon
            & > path
              fill: $color-blue-dark

          & > .label
            color: $color-blue-dark

        & > .separator
          background-color: $color-blue-dark

      &.-done
        & > .step_button
          & > .icon
            & > path
              fill: $color-blue-dark

          & > .label
            color: $color-blue-dark

      & > .step_button
        align-items: center
        background: none
        border: none
        cursor: pointer
        display: flex
        flex-direction: column
        padding: 0


        & > .icon
          display: block

          & > path
            fill: rgba($color-blue-dark, 0.4)

        & > .label
          color: rgba($color-blue-dark, 0.4)
          display: block

      & > .separator
        background-color: rgba($color-blue-dark, 0.15)
        border: none
        display: block
        height: 2px
        margin: 0
        width: 34px

        @media (min-width: 1200px)
          width: 76px
