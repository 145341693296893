@import 'src/styles/shared'

.square_button
  align-items: center
  border: none
  display: flex
  flex-direction: column-reverse
  height: 70px
  justify-content: center
  width: 70px

  &.-default
    background-color: $color-white
    border-radius: 4px

  &.-trans
    background: none

  &.-auto
    height: auto
    padding: 0
    width: auto

    & > .icon
      margin-top: 0

  &.-large
    & > .label
      font-size: 14px

  &.-small
    & > .label
      font-size: 12px

  & > .icon
    display: block
    height: 28px
    margin: 4px
    width: 28px

  & > .label
    color: $color-blue-dark
    line-height: 22px
