#exterior_canvas
  position: relative

  & > .base
    display: block
    width: 100%

  & > .layer
    left: 0
    position: absolute
    top: 0
    width: 100%

    &.-facade
      z-index: 1

    &.-roof
      z-index: 2

    &.-climate
      z-index: 3
