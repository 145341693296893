@import 'src/styles/shared'

#interior_canvas
  position: relative

  & > .slider-wrapper
    overflow: hidden

    & > .slider
      display: flex
      flex-direction: row
      list-style: none
      margin: 0
      padding: 0
      transition: 0.3s ease
      width: 300%

      & > .item
        flex: 0 0 calc(100% / 3)

        & > .image
          display: block
          width: 100%

  & > .navigation-wrapper
    left: 0
    margin-top: -16px
    padding: 0 24px
    position: absolute
    top: 60%
    width: 100%

    & > .navigation
      display: flex
      flex-direction: row
      justify-content: space-between

      & > .next,
      & > .prev
        background-color: $color-blue-dark
        border: none
        border-radius: 50px
        cursor: pointer
        height: 32px
        width: 32px

        &:disabled
          background-color: $color-grey-medium

          & > .icon > path
            fill: $color-black

        & > .icon
          display: block
          height: 16px
          margin: 0 auto
          width: 16px

          & > path
            fill: $color-white

  & > .pagination-wrapper
    bottom: 24px
    left: 50%
    position: absolute
    transform: translateX(-50%)

    & > .pagination
      display: flex
      gap: 8px
      list-style: none
      margin: 0 auto
      padding: 0

      & > .item
        background-color: $color-blue-dark
        border:
          color: $color-blue-dark
          radius: 10px
          style: solid
          width: 1px
        display: block
        height: 8px
        width: 8px

        &.-active
          background-color: $color-white
