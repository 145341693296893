@import 'src/styles/shared'

.dropdown_checkbox_item
  background: none
  border: none
  cursor: pointer
  display: flex
  gap: 16px
  padding: 0 8px
  width: 100%

  &.-small
    & > .content-wrapper
      & > .content
        & > .label-wrapper
          & > .label,
          & > .cost
            font-size: 18px
            line-height: 20px

        & > .detail-wrapper
          font-size: 16px
          line-height: 22px

  & > .input-wrapper
    flex: 0 0 auto

    & > .input
      @extend %checkbox

  & > .content-wrapper
    flex: 1 1 auto

    & > .content
      text-align: left

      & > .label-wrapper
        @extend %dropdown-label-wrapper

        & > .label,
        & > .cost
          font-size: 18px
          line-height: 22px

      & > .detail-wrapper
        font-size: 16px
        line-height: 20px
