.summary_item_table
  display: flex
  flex-direction: column
  gap: 16px

  & > .block-wrapper
    & > .block
      display: flex
      flex-direction: column

      & > .title-wrapper
        & > .title
          font-family: 'ShapeSans Bold', sans-serif
          margin-bottom: 0

      & > .selection_list-wrapper
        & > .selection_list
          display: flex
          flex-direction: column
          gap: 16px

          & > .item
            display: flex
            flex-direction: row
            gap: 16px
            justify-content: space-between

            & > .value
              display: block

              &.-bold
                font-family: 'ShapeSans Bold', sans-serif

            & > .cost
              display: block
              flex: 0 0 100px
              text-align: right
