.modal
  & > .backdrop
    position: fixed
    width: 100vw
    height: 100vh
    z-index: 10
    background-color: rgba(0, 0, 0, 0.4)
    display: block
    left: 0
    top: 0

  & > .box
    background-color: white
    height: 100vh
    left: 0
    position: absolute
    top: 0
    width: 100vw
    z-index: 11

    @media (min-width: 768px)
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
      left: 50%
      top: 50%
      transform: translateX(-50%) translateY(-50%)
