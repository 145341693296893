%button
  border:
    radius: 36px
    style: solid
    width: 1px
  padding: 24px 12px

%radio
  background-color: $color-white
  border:
    color: $color-white
    radius: 40px
    style: solid
    width: 4px
  box-shadow: 0 0 0 1px $color-grey-dark
  display: block
  height: 24px
  margin: 1px
  width: 24px

  &.-selected
    background-color: $color-blue-dark
    box-shadow: 0 0 0 1px $color-black

%checkbox
  border:
    color: $color-black
    radius: 2px
    style: solid
    width: 1px
  display: block
  height: 24px
  width: 24px

  &.-selected
    background:
      color: $color-blue-dark
      image: url('../assets/icons/checkmark.svg')
      position: center
      repeat: no-repeat
      size: auto
    border-color: $color-blue-dark

%dropdown-label-wrapper
  align-items: center
  display: flex
  flex-wrap: wrap
  gap: 4px
  margin-bottom: 4px

  @media (min-width: 1800px)
    gap: 8px
    margin-bottom: 8px

  & > .label
    font-weight: normal
    margin: 0

  & > .cost
    color: $color-grey-dark

%note-wrapper
  & > .note
    display: flex
    flex-direction: row
    gap: 8px

    & > .badge-wrapper
      flex: 1 0 auto

    & > .paragraph-wrapper
      flex: 0 1 100%
      padding: 1px 0

      & > .paragraph
        margin-bottom: 0

%scrollbar
  padding-right: 8px

  &::-webkit-scrollbar
    width: 8px

  &::-webkit-scrollbar-track
    background: $color-white

  &::-webkit-scrollbar-thumb
    background: $color-grey-medium
    border-radius: 4px

  &::-webkit-scrollbar-thumb:hover
    background: $color-blue-dark
