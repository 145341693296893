@import 'src/styles/shared'

.dropdown
  display: flex
  flex-direction: column
  gap: 4px

  &.-open
    gap: 24px
    margin-bottom: 24px

    & > .dropdown_body
      height: auto

  & > .dropdown_header
    align-items: center
    background-color: rgba($color-blue-light, 0.15)
    border: none
    cursor: pointer
    display: flex
    justify-content: space-between
    padding: 16px 20px

    & > .title-wrapper
      & > .title
        font-family: 'ShapeSans Regular', sans-serif
        font-size: 20px
        font-weight: normal
        line-height: 28px
        margin-bottom: 0

    & > .icon-wrapper
      & > .icon
        display: block

  & > .dropdown_body
    display: flex
    flex-direction: column
    height: 0
    overflow: hidden
