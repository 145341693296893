@import 'src/styles/shared'
.help_modal
  & > .box
    height: calc(100vh - 40px)
    max-height: 938px
    max-width: 1610px
    overflow-y: scroll
    padding: 40px
    width: calc(100% - 40px)

    @media (min-width: 1200px)
      padding: 60px 90px

    & > .button-wrapper
      display: flex
      justify-content: flex-end
      margin-bottom: 56px

    & > .content-wrapper
      & > .content
        display: flex
        flex-direction: column
        gap: 40px

        @media (min-width: 1200px)
          flex-direction: row
          gap: 80px

        & > .primary_content-wrapper
          flex: 0 1 auto

          & > .primary_content
            & > .title-wrapper
              margin-bottom: 40px

              & > .title
                color: $color-blue-dark
                font-size: 34px
                line-height: 40px
                margin-bottom: 0

            & > .content-wrapper
              border-bottom: solid 1px $color-black
              margin-bottom: 56px
              padding-bottom: 56px

              & > .content
                display: flex
                flex-direction: column
                gap: 40px

                & > .section-wrapper
                  & > .section
                    & > .title-wrapper
                      margin-bottom: 16px

                      & > .title
                        color: $color-blue-dark
                        font-size: 24px
                        font-weight: normal
                        line-height: 28px
                        margin-bottom: 0

                    & > .text-wrapper
                      display: flex
                      flex-direction: column
                      font-size: 18px
                      gap: 24px
                      line-height: 26px

                      & > .paragraph
                        margin-bottom: 0

        & > .secondary_content-wrapper
          flex: 0 0 620px

          & > .secondary_content
            background-color: #ECECEC
            display: flex
            flex-direction: column
            gap: 24px
            padding: 40px

            & > .section-wrapper
              & > .section
                & > .title-wrapper
                  margin-bottom: 8px

                  & > .title
                    color: $color-blue-dark
                    font-size: 18px
                    line-height: 20px
                    margin-bottom: 0

                & > .text-wrapper
                  display: flex
                  flex-direction: column
                  gap: 24px

                  & > .paragraph
                    margin-bottom: 0
