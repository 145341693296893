@import 'src/styles/shared'

.contact_modal
    min-width: 800px
    margin: 50px

    & > .box
        height: auto
        padding: 40px
        width: auto

        @media (min-width: 1200px)
            padding: 80px

        & > .header-wrapper
            margin-bottom: 40px

            & > .header
                display: flex
                gap: 32px
                justify-content: space-between

                & > .title-wrapper
                    & > .title
                        color: $color-blue-dark
                        font-size: 34px
                        line-height: 40px
                        margin-bottom: 0

        & > .information-wrapper
            & > .information
                align-items: center
                display: flex
                flex-direction: row
                gap: 24px

                & > .image-wrapper
                    flex: 0 0 auto

                    & > .image
                        display: block
                        height: auto
                        max-width: 120px

                & > .text-wrapper
                    flex: 0 0 auto

                    & > .text
                        display: block
                        font-size: 18px
                        line-height: 26px
                        margin-bottom: 0

                        & > .label
                            display: block

                            &.-hyperlink
                                color: $color-blue-dark

        & > .logo-wrapper
            margin-bottom: 48px

            & > .logo
                display: block
                max-width: 120px

        & > .contact-wrapper
            & > .contact
                display: flex
                flex-direction: row
                gap: 40px

                @media (min-width: 1200px)
                    gap: 160px

                & > .column-wrapper
                    flex: 0 1 calc(100% / 3)

                    & > .column
                        & > .title
                            display: block
                            font-size: 24px
                            font-weight: normal
                            line-height: 28px
                            margin-bottom: 0

                        & > .phone
                            color: $color-blue-dark
                            display: block
                            font-size: 18px
                            line-height: 26px
                            margin-bottom: 24px

                        & > .text
                            font-size: 18px
                            line-height: 26px
                            margin-bottom: 0

                        & > .email
                            color: $color-blue-dark
                            display: block
                            font-size: 18px
                            line-height: 26px
                            margin-top: 24px
