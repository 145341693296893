#loader
  background-color: rgba(255, 255, 255, 0.7)
  height: 100vh
  left: 0
  position: fixed
  top: 0
  width: 100vw
  z-index: 10

  & > .image
    display: block
    height: auto
    left: 50%
    max-width: 200px
    position: absolute
    top: 50%
    transform: translateX(-50%) translateY(-50%)
