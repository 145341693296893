@import 'src/styles/shared'

#tab_menu
  border-bottom: solid 1px #D6D6D6
  display: flex
  flex-wrap: wrap
  gap: 24px
  list-style: none
  margin: 0
  max-width: fit-content
  padding: 0 0 10px

  & > .item
    position: relative

    &.-active
      &:after
        background-color: $color-blue-dark
        bottom: -12px
        content: ''
        display: block
        height: 3px
        left: 0
        position: absolute
        width: 100%

    & > .tab
      background: none
      border: none
      cursor: pointer
      display: block
      font-size: 18px
      line-height: 26px
      padding: 0

