@import 'src/styles/shared'

.dropdown_radio_item
  background: none
  cursor: pointer
  display: flex
  flex-direction: row
  gap: 16px
  padding: 0
  width: 100%

  &.-default
    border: none
    padding: 0 8px

    & > .input-wrapper
      flex: 0 0 auto

    & > .content-wrapper
      flex: 1 1 auto

  &.-image
    align-items: center
    border:
      color: $color-grey-medium
      radius: 4px
      style: solid
      width: 1px

    &.-selected
      border-color: $color-black

    & > .image-wrapper
      flex: 0 0 130px

      & > .image
        display: block
        max-width: 100%

    & > .content-wrapper
      flex: 1 1 auto

    & > .input-wrapper
      flex: 0 0 auto
      padding-right: 24px

  &.-selected
    border-color: $color-black

  & > .content-wrapper
    & > .content
      text-align: left

      & > .label-wrapper
        @extend %dropdown-label-wrapper

        & > .label,
        & > .cost
          font-size: 18px
          line-height: 20px

      & > .detail-wrapper
        font-size: 16px
        line-height: 22px

  & > .input-wrapper
    & > .input
      @extend %radio
