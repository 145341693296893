@import 'src/styles/shared'
.small_screen_modal
  & > .box
    display: flex
    flex-direction: column
    justify-content: center
    max-width: none
    padding: 60px 30px

    @media (min-width: 768px)
      height: auto
      max-width: 375px
      width: calc(100% - 40px)

    & > .icon-wrapper
      margin-bottom: 40px

      & > .icon
        display: block
        margin: 0 auto

    & > .text-wrapper
      font-size: 16px
      line-height: 22px
      text-align: center

      @media (min-width: 768px)
        font-size: 18px
        line-height: 26px

      &.-one,
      &.-two
        margin-bottom: 32px

        @media (min-width: 768px)
          margin-bottom: 40px

      & > .paragraph
        margin-bottom: 0

    & > .link-wrapper
      margin-bottom: 32px

      @media (min-width: 768px)
        margin-bottom: 40px

      & > .anchor
        margin: 0 auto
        flex-direction: row-reverse

        & > .label
          display: inline-block
          font-family: 'ShapeSans Medium', sans-serif
          font-size: 16px
          line-height: 26px

          @media (min-width: 768px)
            font-size: 18px

    & > .button-wrapper
      & > .button
        margin: 0 auto
