@import 'src/styles/shared'

.option_list
  padding: 32px 0

  & > .title-wrapper
    margin-bottom: 70px

    & > .title
      color: $color-blue-dark
      font-family: 'ShapeSans Medium', sans-serif
      font-size: 24px
      line-height: 30px
      margin-bottom: 0

      @media (min-width: 1200px)
        font-size: 34px
        line-height: 40px

  & > .list-wrapper
    & > .list
      display: flex
      flex-direction: column
      gap: 64px
      list-style: none
      margin: 0
      padding: 0

      & > .item
        & > .option
          background: none
          border: none
          cursor: pointer
          display: flex
          gap: 32px
          padding: 0
          text-align: left

          & > .checkbox-wrapper
            flex: 0 1 24px

            & > .checkbox
              @extend %radio

          & > .label-wrapper
            flex: 1 0 calc(100% - 56px)

            & > .label
              display: block
              font-size: 24px
              line-height: 28px
              margin-bottom: 8px

            & > .note
              display: block
              opacity: 0

              &.-visible
                opacity: 1
