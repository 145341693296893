*,
*:before,
*:after
  box-sizing: border-box

html
  height: 100%

body
  color: $color-black
  font:
    family: 'ShapeSans Regular', sans-serif
    size: 16px
  height: 100%
  line-height: 22px

#root
  display: flex
  flex-direction: column
  height: 100%

#main
  flex: 1 1 auto
  height: calc(100vh - 120px)
  overflow-y: auto

a
  text-decoration: none

img
  max-width: 100%

nav h1
  margin-right: auto

.product
  display: grid
  gap: 30px

.offers, .products
  display: flex
  gap: 20px
