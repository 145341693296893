@import 'src/styles/shared'

%ratio-item
  display: flex
  flex-direction: column

  & > .label-wrapper
    & > .label
      font-size: 14px
      font-weight: normal
      line-height: 16px
      margin-bottom: 0

  & > .data-wrapper
    & > .data
      align-items: baseline
      display: flex
      flex-direction: row
      gap: 4px

      & > .value-wrapper
        & > .value
          display: block
          font-size: 34px
          line-height: 40px

      & > .suffix-wrapper
        & > .suffix
          display: block
          font-size: 14px
          line-height: 16px

#preview_ratio
  display: flex
  flex-direction: column

  & > .ratio
    align-items: center
    background-color: $color-blue-dark
    color: $color-white
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 16px
    justify-content: space-between
    padding: 16px

    //@media (min-width: 1100px)
      //align-items: center
      //flex-direction: row
      //gap: 0

    & > .concept-wrapper
      flex: 0 0 auto

      & > .concept
        display: flex
        flex-direction: column

        & > .label-wrapper
          margin-bottom: 4px

          & > .label
            display: block
            font-size: 18px
            font-weight: normal
            line-height: 20px
            margin-bottom: 0

        & > .detail-wrapper
          & > .detail
            display: block
            font-size: 14px
            line-height: 16px

    & > .ratio-wrapper
      flex: 0 0 auto

      & > .ratio
        display: flex
        flex-direction: row

        & > .carbon_footprint-wrapper
          border-right: solid 1px $color-white
          padding-right: 20px

          & > .carbon_footprint
            @extend %ratio-item

        & > .rental_charge-wrapper
          border-right: solid 1px $color-white
          padding: 0 20px

          & > .rental_charge
            @extend %ratio-item

        & > .estimated_total_cost-wrapper
          padding-left: 20px

          & > .estimated_total_cost
            @extend %ratio-item
            & > .data-wrapper
              & > .data
                & > .suffix-wrapper
                  & > .suffix
                    font-size: 34px
                    line-height: 40px

  & > .note
    background-color: $color-blue-light
    color: $color-white
    font-size: 14px
    line-height: 16px
    padding: 12px 16px
    text-align: right

    & > .paragraph
      margin: 0
