@import 'src/styles/shared'

#splash
  background-color: $color-white
  display: none
  height: 100vh
  left: 0
  overflow-y: scroll
  position: fixed
  top: 0
  width: 100vw
  z-index: 10

  &.-open
    display: block

  & > .button
    position: fixed
    right: 50px
    top: 40px
    z-index: 11
