@import 'src/styles/shared'

.summary_item
  &:not(:last-of-type)
    border-bottom: solid 1px $color-grey-medium
    margin-bottom: 32px
    padding-bottom: 32px

  & > .title-wrapper
    & > .title
      color: $color-blue-dark
      font:
        size: 16px
        weight: bold
      line-height: 22px
      margin-bottom: 0

  & > .content-wrapper
    & > .content
      & > .paragraph
        margin-bottom: 32px

        &:last-of-type
          margin-bottom: 0

      & > .table
        display: flex
        flex-direction: column
        gap: 16px

        & > .block-wrapper
          & > .block
            display: flex
            flex-direction: column

            & > .title-wrapper
              & > .title
                font-family: 'ShapeSans Bold', sans-serif
                margin-bottom: 0

            & > .selection_list-wrapper
              & > .selection_list
                & > .item
                  display: flex
                  flex-direction: row
                  gap: 16px
                  justify-content: space-between

                  & > .value
                    display: block

                    &.-bold
                      font-family: 'ShapeSans Bold', sans-serif

                  & > .cost
                    display: block
                    flex: 0 0 100px
                    text-align: right
