@import 'src/styles/shared'

#preview_canvas_info
  position: absolute
  left: 20px
  top: 22px
  z-index: 6

  &.-closed
    background-color: $color-white
    border: none
    border-radius: 50px
    cursor: pointer
    padding: 10px

    & > .icon
      display: block

  &.-open
    background-color: $color-white
    box-shadow: 0 4px 4px rgba($color-black, 0.25)
    display: flex
    flex-direction: column
    gap: 24px
    max-width: 858px
    padding: 40px
    width: calc(100% - 40px)

    & > .content-wrapper
      flex: 0 1 auto

      & > .content
        display: flex
        flex-direction: row
        gap: 32px

        & > .inner-wrapper
          & > .inner
            & > .title-wrapper
              margin-bottom: 8px

              & > .title
                color: $color-blue-dark
                font-size: 18px
                line-height: 20px
                margin-bottom: 0

            & > .text-wrapper
              & > .paragraph
                margin-bottom: 0

    & > .note-wrapper
      @extend %note-wrapper
