@import 'src/styles/shared'

#submit_form
  display: flex
  flex-direction: column

  & > .title-wrapper
    margin-bottom: 24px

    & > .title
      color: $color-blue-dark
      font-family: 'ShapeSans Medium', sans-serif
      font-size: 24px
      font-weight: normal
      line-height: 28px
      margin-bottom: 0

  & > .text-wrapper
    font-size: 18px
    line-height: 22px
    margin-bottom: 40px

    & > .paragraph
      margin-bottom: 0

  & > .form-wrapper
    & > #form
      display: flex
      flex-direction: column

      & > .field-wrapper
        margin-bottom: 40px

        & > .field
          display: flex
          flex-direction: column

          & > .label-wrapper
            margin-bottom: 12px

            & > .label
              color: $color-blue-dark
              display: block
              font-size: 18px
              line-height: 26px

          & > .input-wrapper
            & > .input
              &.-text
                border:
                  color: $color-grey-medium
                  radius: 4px
                  style: solid
                  width: 1px
                color: #737373
                display: block
                font-size: 18px
                line-height: 26px
                padding: 12px 16px
                width: 100%

              &.-radio
                display: flex
                flex-direction: row
                gap: 40px
                list-style: none
                margin: 0
                padding: 0

                & > .item-wrapper
                  & > .item
                    align-items: center
                    background: none
                    border: none
                    cursor: pointer
                    display: flex
                    flex-direction: row
                    gap: 8px
                    padding: 0

                    & > .input-wrapper
                      & > .input
                        @extend %radio

                    & > .label-wrapper
                      & > .label
                        font-size: 16px
                        line-height: 22px

      & > .consent-wrapper
        margin-bottom: 40px

        & > .consent
          background: none
          border: none
          cursor: pointer
          display: flex
          flex-direction: row
          gap: 8px
          padding: 0
          text-align: left

          & > .input-wrapper
            & > .input
              @extend %checkbox

          & > .label-wrapper
            & > .label
              font-size: 14px
              line-height: 18px

      & > .button-wrapper
        display: flex
        justify-content: flex-end
