@import 'src/styles/shared'

#success_message
  background-color: $color-grey-light
  padding: 80px 40px
  text-align: center

  & > .buddy-wrapper
    margin-bottom: 40px

    & > .buddy
      display: block
      margin: 0 auto

  & > .title-wrapper
    margin-bottom: 32px

    & > .title
      color: $color-blue-dark
      font-family: 'ShapeSans Medium', sans-serif
      font-size: 24px
      line-height: 30px
      margin-bottom: 0

  & > .text-wrapper
    margin-bottom: 40px

    & > .paragraph
      margin-bottom: 0

      & > .hyperlink
        color: $color-blue-dark
        font-family: 'ShapeSans Medium', sans-serif

        &:hover
          text-decoration: underline

  & > .button-wrapper
    display: flex
    justify-content: center
